import React, { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { link_gform } from "../../../api/const";
import { ApiContext } from "../../../context/ApiProvider";

export default function WhatWeHelp() {
  const {data} = useContext(ApiContext)
  return (
    <section
      data-aos="fade-up"
      id="whatwehelp"
      class="bg-white dark:bg-gray-900"
    >
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div>
          <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[8px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
            <div class="rounded-lg overflow-hidden h-[156px] md:h-[278px] bg-white dark:bg-gray-800">
              <Carousel autoPlay={true} interval={1000} infiniteLoop={true} showIndicators={false} labels={false} renderIndicator={false} showStatus={false}>
                <div>
                  <img
                    src="/assets/img/marginal-screen.png"
                    class="dark:hidden h-[156px] md:h-[278px] w-full rounded-xl"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/assets/img/screen2.png"
                    class="dark:hidden h-[156px] md:h-[278px] w-full rounded-xl"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/assets/img/screen3.png"
                    class="dark:hidden h-[156px] md:h-[278px] w-full rounded-xl"
                    alt=""
                  />
                </div>
              </Carousel>
            </div>
          </div>
          <div class="relative mx-auto bg-gray-900 dark:bg-gray-700 rounded-b-xl rounded-t-sm h-[17px] max-w-[351px] md:h-[21px] md:max-w-[597px]">
            <div class="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-xl w-[56px] h-[5px] md:w-[96px] md:h-[8px] bg-gray-800"></div>
          </div>
        </div>

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Bagaimana Transpublika dapat membantu anda
          </h2>
          <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Pelayanan yang kami berikan bersifat asistensi dan All In pelayanan.
            Kami siap membantu Anda mulai dari konversi jurnal, penyempurnaan
            naskah jurnal, publikasi jurnal Nasional dan Internasional serta
            melegalitaskan karya Anda melalui Hak Kekayaan Intelektual (HKI).
          </p>
          <a
            href={data.context.link_form}
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
          >
           Segera Hubungi Kami !
            
          </a>
        </div>
      </div>
    </section>
  );
}
