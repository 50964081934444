import React from "react";
import FeaturesWrapper from "../blocks/feature";
import FeaturesBody, {
  FeaturesDesc,
  FeaturesTitle,
} from "../blocks/feature/content";
import { AiFillPrinter, AiOutlineTranslation } from "react-icons/ai";
import {
  FaAssistiveListeningSystems,
  FaJournalWhills,
  FaSearch,
} from "react-icons/fa";
import { BiText } from "react-icons/bi";

function Features() {
  return (
    <section data-aos="fade-up" id="features" class="bg-white dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Layanan Kami
          </h2>
          <p class="text-gray-500 sm:text-xl dark:text-gray-400">
            Selain menerbitkan artikel di jurnal terakreditasi nasional dan
            bereputasi internasional, Transpublika juga berkomitmen memberikan
            dukungan kepada para akademisi, dosen dan peneliti dalam menyebarkan
            hasil karya ilmiah dan publikasi di jurnal internasional, yang
            diwujudkan ke dalam layanan kepenulisan, Penerjemahan dan Publikasi.
          </p>
        </div>
        <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <FeaturesWrapper>
            <FeaturesBody icon={<AiOutlineTranslation size={30} />}>
              <FeaturesTitle>Translate / Penerjemahan</FeaturesTitle>
              <FeaturesDesc>
                Kami menangani semua jenis proyek terjemahan setiap hari. Tim
                penerjemah kami yang berpengalaman dan berkualifikasi akan
                melakukan yang terbaik untuk menangani semua kebutuhan
                terjemahan Anda. Kami Sangat Mengedepankan Kualitas!
              </FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <FeaturesBody icon={<FaSearch size={30} />}>
              <FeaturesTitle>Proofread</FeaturesTitle>
              <FeaturesDesc>
                Merupakan layanan penyuntingan bahasa untuk naskah artikel
                ilmiah yang disiapkan untuk publikasi ke jurnal nasional dan
                internasional (semua pengindeks). Layanan disupervisi oleh
                editor bahasa native speaker (penutur asli Bahasa Inggris)
                bergelar Ph.D / M.Ed.
              </FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <FeaturesBody icon={<BiText size={30} />}>
              <FeaturesTitle>
                Paraphrase (untuk menurunkan similarity)
              </FeaturesTitle>
              <FeaturesDesc>
                Merupakan layanan penyuntingan bahasa untuk naskah artikel
                ilmiah yang disiapkan untuk publikasi ke jurnal nasional dan
                internasional (semua pengindeks). Layanan disupervisi oleh
                editor bahasa native speaker (penutur asli Bahasa Inggris)
                bergelar Ph.D / M.Ed.
              </FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <FeaturesBody icon={<FaAssistiveListeningSystems size={30} />}>
              <FeaturesTitle>Layanan Asistensi Publikasi</FeaturesTitle>
              <FeaturesDesc>
                Layanan ini bertujuan membantu Anda mengubah bahan tulisan (bisa
                berupa gagasan, skripsi, tesis, disertasi, artikel, maupun modul
                ajar) menjadi artikel yang layak dipublikasikan.
              </FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <FeaturesBody icon={<FaJournalWhills size={30} />}>
              <FeaturesTitle>Asistensi Pengelolaan Jurnal</FeaturesTitle>
              <FeaturesDesc>
                Layanan Asistensi Pengelolaan Jurnal ( Pembuatan Jurnal OJS,
                Indeksasi, Kustomisasi, Setting DOI dan Akreditasi Jurnal)
              </FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <FeaturesBody icon={<FaJournalWhills size={30} />}>
              <FeaturesTitle>
                Pengelolaan Jurnal Institusi atau Lembaga
              </FeaturesTitle>
              <FeaturesDesc>
                Layanan Asistensi Pengelolaan Jurnal ( Pembuatan Jurnal OJS,
                Indeksasi, Kustomisasi, Setting DOI dan Akreditasi Jurnal )
                Institusi atau Lembaga
              </FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
          <FeaturesWrapper>
            <FeaturesBody icon={<AiFillPrinter size={30} />}>
              <FeaturesTitle>Percetakan Buku ISBN</FeaturesTitle>
              <FeaturesDesc>Layanan pencetakan Buku ISBN</FeaturesDesc>
            </FeaturesBody>
          </FeaturesWrapper>
        </div>
      </div>

      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Call For Paper
          </h2>
        </div>
        <div class="space-y-8 lg:grid lg:grid-cols-4 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src="/assets/img/callforpaper/calling2.png" alt="" />
          </div>
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src="/assets/img/callforpaper/calling3.png" alt="" />
          </div>
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src="/assets/img/callforpaper/calling4.png" alt="" />
          </div>
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src="/assets/img/callforpaper/calling6.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
