import React from "react";
import FaqWrapper from "../blocks/faq";
import FaqBody, { FaqDesc, FaqTitle } from "../blocks/faq/content";

export default function Faq() {
  return (
    <section id="faq" class="bg-white dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Frequently asked questions (FAQ)
        </h2>
        <FaqWrapper>
          <div>
            <FaqBody>
              <FaqTitle>Apa sajakah produk yang kami miliki?</FaqTitle>
              <FaqDesc>
                Kami menawarkan produk tentunya penerbitan jurnal internasional,
                penerbitan jurnal terakreditasi, pengeditan naskah, pengubahan
                tugas akhir menjadi jurnal, cek plagiasi, penambahan sitasi,
                pembuatan buku, penerjemahan naskah dan masih banyak lagi.
              </FaqDesc>
            </FaqBody>
            <FaqBody>
              <FaqTitle>Biaya publikasi meliputi apa saja sih?</FaqTitle>
              <FaqDesc>
                Nah, biayanya ini sudah mencakup keseluruhan dan dijamin
                terjangkau. Cakupan biayanya yaitu gratis Publish, naskah
                terpublikasi selamanya, gratis template premium, gratis
                mendeley, gratis cek plagiasi (turnitin, plagiarism checker X),
                gratis alur OJS, jaminan terbit 100%, garansi uang kembali jika
                tidak terbit, gratis translate dan LoA diproses cepat.
              </FaqDesc>
            </FaqBody>
            <FaqBody>
              <FaqTitle>
                Bolehkah pembayaran dilakukan secara bertahap?
              </FaqTitle>
              <FaqDesc>
                Jelas boleh dong, proses pembayaran dapat dilakukan secara
                bertahap. Alur pembayarannya, kamu bisa melakukan pembayaran
                tahap awal dulu, kemudian naskah di proses oleh kami, setelah
                mendekati publish baru kamu melakukan pelunasannya.
              </FaqDesc>
            </FaqBody>
          </div>
          <div>
            <FaqBody>
              <FaqTitle>
                Bagaimana proses publikasi yang dilakukan oleh kami?
              </FaqTitle>
              <FaqDesc>
                Naskah masuk langsung kami proses, kemudian Langkah awal yaitu
                proses Screening atau seleksi awal naskah untuk mengecek
                kesesuaian naskah dengan scope jurnal dan tingkat plagiasi
                naskah.
              </FaqDesc>
            </FaqBody>
            <FaqBody>
              <FaqTitle>
                Apa saja yang menjadi pertimbangan kami dalam menerima naskah
                kamu?
              </FaqTitle>
              <FaqDesc>
                Naskah kamu harus sesuai dengan ketentuan template premium kami
                dan tentunya merupakan karya orisinil dan ilmiah.
              </FaqDesc>
            </FaqBody>
            <FaqBody>
              <FaqTitle>
                Waktu yang dibutuhkan untuk menerbitkan naskah di kami berapa
                lama sih?
              </FaqTitle>
              <FaqDesc>
                Naskah yang kami terbitkan yaitu rentang waktu 1-3 bulan dan
                pasti terbit.
              </FaqDesc>
            </FaqBody>
            <FaqBody>
              <FaqTitle>Mengapa kamu harus memilih kami?</FaqTitle>
              <FaqDesc>
                Tentunya, kami merupakan perusahaan publikasi professional,
                terpercaya, cepat dan mudah.
              </FaqDesc>
            </FaqBody>
          </div>
        </FaqWrapper>
      </div>
    </section>
  );
}
