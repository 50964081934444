import axios from "axios";
import { backendUrl } from "./const";
const instance = axios.create({
  baseURL: `${backendUrl}/api/v1/`,
  headers: {
    "Content-Type": "text/plain",
  },
});

export default {
  postData: (data) =>
    instance({
      method: "POST",
      url: "form/store_visitor",
      data: data,
    }),
  getData: (url, param) =>
    instance({
      method: "GET",
      url: url,
      params: param,
    }),
};
