import React, { Children } from "react";
import PricingWrapper, {
  PricingCard,
  PricingListItem,
  PricingListWrap,
  PricingSubtitle,
  PricingTitle,
} from "../blocks/Pricing";
import MyButton from "../../lil/button";
import MySpacer from "../../lil/spacer"

export default function WhyMeSection() {
  return (
    <section
      data-aos="fade-up"
      id="whymesection"
      class="bg-white dark:bg-gray-900"
      style={{
        background: 'url("/assets/bg-melengkung.svg")',
        backgroundSize: "cover",
      }}
    >
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Mengapa Harus Memilih Kami
          </h2>
          <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Transpublika adalah solusi dalam penerjemahan dan publikasi ilmiah
            yang didirikan kepedulian bangsa terhadap penyebaran dan publikasi
            ilmiah.
          </p>
        </div>
        <PricingWrapper>
          <PricingCard>
            <PricingTitle>Asistensi Publikasi</PricingTitle>
            <PricingSubtitle>Layanan Asistensi Publikasi</PricingSubtitle>
            <MySpacer />
            <PricingListWrap>
              <PricingListItem>Publikasi SINTA</PricingListItem>
              <PricingListItem>Publikasi DOAJ</PricingListItem>
              <PricingListItem>Publikasi COPERNICUS</PricingListItem>
              <PricingListItem>Publikasi EBSCO</PricingListItem>
              <PricingListItem>Publikasi Web of Science ( WOS)</PricingListItem>
              <PricingListItem>Publikasi SCOPUS</PricingListItem>
            </PricingListWrap>
            <MySpacer />
            <MyButton>Hubungi sekarang</MyButton>
          </PricingCard>
          <PricingCard>
            <PricingTitle>Asistensi Pengelolaan Jurnal </PricingTitle>
            <PricingSubtitle>
              Layanan Asistensi Pengelolaan Jurnal{" "}
            </PricingSubtitle>
            <MySpacer />
            <PricingListWrap>
              <PricingListItem>Pembuatan Jurnal OJS</PricingListItem>
              <PricingListItem>Indeksasi</PricingListItem>
              <PricingListItem>Kustomisasi</PricingListItem>
              <PricingListItem>Setting DOI </PricingListItem>
              <PricingListItem>Akreditasi Jurnal</PricingListItem>
            </PricingListWrap>
            <MySpacer />
            <MyButton>Hubungi sekarang</MyButton>
          </PricingCard>
          <PricingCard>
            <PricingTitle>Pengelolaan Jurnal Institusi</PricingTitle>
            <PricingSubtitle>
              Layanan Pengelolaan Jurnal Institusi atau Lembaga
            </PricingSubtitle>
            <MySpacer />
            <PricingListWrap>
              <PricingListItem>Pembuatan Jurnal OJS</PricingListItem>
              <PricingListItem>Indeksasi</PricingListItem>
              <PricingListItem>Kustomisasi</PricingListItem>
              <PricingListItem>Setting DOI </PricingListItem>
              <PricingListItem>Akreditasi Jurnal</PricingListItem>
            </PricingListWrap>
            <MySpacer />
            <MyButton>Hubungi sekarang</MyButton>
          </PricingCard>
        </PricingWrapper>
      </div>
    </section>
  );
}
