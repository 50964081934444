import React from 'react'

export default function IndexingSlider() {
  return (
    
    <div data-aos="fade-up" class="bg-white dark:bg-gray-900">
    <div class="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
      <h2 class="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl">
        Layanan Indeksasi Jurnal
      </h2>
      <div id='index-slider' class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-5 dark:text-gray-400">
        <a href="#" class="flex justify-center items-center">
          <img src="/assets/img/indexing/image 26.png" alt="" />
        </a>
        <a href="#" class="flex justify-center items-center">
          <img src="/assets/img/indexing/image 27.png" alt="" />
        </a>
        <a href="#" class="flex justify-center items-center">
          <img src="/assets/img/indexing/image 28.png" alt="" />
        </a>
        <a href="#" class="flex justify-center items-center">
          <img src="/assets/img/indexing/image 29.png" alt="" />
        </a>
        <a href="#" class="flex justify-center items-center">
          <img src="/assets/img/indexing/image 30.png" alt="" />
        </a>
      </div>
    </div>
  </div>
  )
}
