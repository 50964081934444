import React, { useContext } from "react";
import { link_gform } from "../../../api/const";
import { ApiContext } from "../../../context/ApiProvider";

function MyButton({ children }) {
  const {data} = useContext(ApiContext) 
  return (
    <a
      href={data.context.link_form}
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
      fdprocessedid="9yv504"
    >
      {children}
    </a>
  );
}

export default MyButton;
