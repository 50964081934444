import "react-whatsapp-widget/dist/index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/home";
import Blog from "./pages/blog";
import NotFound from "./pages/notFound";
import BlogLists from "./pages/blog/blogLists";
import BlogPost from "./pages/blog/blogPost";
import api from "./api/api";
import JurnalKami from "./pages/jurnalKami";
import { ApiProvider } from "./context/ApiProvider";

export default function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <ApiProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<Blog />}>
            <Route index element={<BlogLists />} />
            <Route path=":slug" element={<BlogPost />} />
          </Route>
          <Route path="/jurnal_kami" element={<JurnalKami />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ApiProvider>
  );
}
