import React from "react";
import { Outlet, useParams } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { WhatsAppWidget } from "react-whatsapp-widget";

import { Helmet } from 'react-helmet';

const BlogPosts = {
  "first-blog-post": {
    title: "First Blog Post",
    description: "Lorem ipsum dolor sit amet, consectetur adip.",
  },
  "second-blog-post": {
    title: "Second Blog Post",
    description: "Hello React Router v6",
  },
};

export { BlogPosts };
function Blog() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Blog",
              "name": "Transpublika Blog",
              "url": "https://transpublika.com/blog",
              "description": "Stay updated with the latest blog posts and articles at Transpublika."
            }
          `}
        </script>
        <title>Transpublika - Blog</title>
        <meta
          name="description"
          content="Stay updated with the latest blog posts and articles at Transpublika."
        />
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
      <WhatsAppWidget
        phoneNumber={localStorage.getItem("whatsapp")}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </>
  );
}

export default Blog;
