import React, { useCallback, useEffect, useState } from "react";
import { Bussiness, Default } from "./header/navbar";

function Header() {
  const [y, setY] = useState(window.scrollY);
  const [onTop, setOnTop] = useState(true)

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setOnTop(false)
      } else if (y < window.scrollY) {
        setOnTop(false)
      }
      if (window.pageYOffset === 0) {
        setOnTop(true)
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    onTop ? <Bussiness onTop={onTop}/> : <Default/>
  );
}

export default Header;
