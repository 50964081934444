import React, { createContext, useState, useEffect } from "react";
import api from "../api/api";

// Buat Context
const ApiContext = createContext();

// Buat Provider untuk menyediakan data dari API
const ApiProvider = ({ children }) => {
  const [data, setData] = useState({
    asset_path: '',
    context: {
        web_logo : '',
        email: '',
        whatsapp: '',
        title: '',
        tag_line: '',
        alamat: '',
        link_form: ''
    }
  });

  useEffect(() => {
    api
      .getData("/web-content/context")
      .then((res) => {
        setData(res.data.data)
        localStorage.setItem("web-logo", res.data.data.context.web_logo);
        localStorage.setItem("web-title", res.data.data.context.title);
        localStorage.setItem("tag-line", res.data.data.context.tag_line);
        localStorage.setItem("asset-path", res.data.data.asset_path);
        localStorage.setItem("alamat", res.data.data.context.alamat);
        localStorage.setItem("whatsapp", res.data.data.context.whatsapp);
        localStorage.setItem("email", res.data.data.context.email);
        localStorage.setItem("link-form", res.data.data.context.link_form);
      })
      .catch((err) => console.log(err));
  }, []);

  return <ApiContext.Provider value={{ data }}>{children}</ApiContext.Provider>;
};

export { ApiContext, ApiProvider };
