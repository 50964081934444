import RadioForm, { RadioItem } from "../../lil/form/radio";
import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import api from "../../../api/api";
import { ApiContext } from "../../../context/ApiProvider";

export default function Contact() {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {data} = useContext(ApiContext)

  const handleChange = (evt) => {
    const name = evt.target.name;
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
      formData.append(key, state[key]);
    });
    api
      .postData(formData)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          Swal.fire(
            "Berhasil!",
            "Permintaan mu sudah dikirim, silahkan tunggu balasan dari admin kami ya!",
            "success"
          );
        } else {
          Swal.fire("Gagal!", res.data.message, "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire("Gagal!", "Silahkan coba lagi", "error");
      });
  };
  return (
    <>
      <div
        data-aos="fade-up"
        id="contact"
        style={{ backgroundColor: "#182c44", color: "white" }}
        class="  rounded-lg  md:p-12 mb-8 mt-16 mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8"
      >
        <div class="flex flex-row justify-around items-center gap-6">
          <div class="flex flex-row justify-around items-center gap-6">
            <div class="sub-left-icon">
              <img src="assets/img/subscribe/sub-icon.png" alt="thumb" />
            </div>
            <div class="sub-left-info">
              <h2 className="text-2xl font-bold text-white mb-2">
                Hubungi Kami
              </h2>
              <p className="text-white">
                Bapak/Ibu siap untuk memulai pekerjaan anda bersama kami?
                Silakan kirim Whatsapp/telepon atau email, kami akan merespon
                anda sesegera mungkin.
              </p>
            </div>
          </div>
          <div class="sub-right mt-4">
            <form>
              <button class="sub-inline-flex justify-center items-center py-2.5 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900 ">
                <a
                  href={data.context.link_form}
                  class="whitespace-nowrap"
                >
                  Hubungi Kami
                </a>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className="max-w-screen-xl mx-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15803.561367144186!2d112.62338529236861!3d-8.010243922253004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd627cf9fefc41d%3A0x821bfdc592542768!2sTranspublika%20Publisher!5e0!3m2!1sid!2sid!4v1686635733611!5m2!1sid!2sid"
          width="100%"
          height="350"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <section data-aos="fade-up" class="bg-white dark:bg-gray-900">
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Hubungi Kami
          </h2>
          <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl"></p>
          <div>
            <form action="#" class="space-y-8">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Alamat email <span className=" text-red-500">*</span>
                </label>
                <input
                  name="email"
                  type="email"
                  id="email"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="name@email.com"
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  for="subject"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Nama Lengkap <span className=" text-red-500">*</span>
                </label>
                <input
                  name="name"
                  type="text"
                  id="subject"
                  class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  for="subject"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  No Whatsapp <span className=" text-red-500">*</span>
                </label>
                <input
                  name="no_whatsapp"
                  type="text"
                  id="subject"
                  class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  for="subject"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Asal Kampus / Instansi{" "}
                  <span className=" text-red-500">*</span>
                </label>
                <input
                  name="asal_institusi"
                  type="text"
                  id="subject"
                  class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  required
                  onChange={handleChange}
                />
              </div>
              <RadioForm label="Jabatan Akademis">
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Mahasiswa Semester (1-5)
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Mahasiswa S1 (Semester 6 - Keatas)
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Mahasiswa S2
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Mahasiswa S3
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Dosen
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Doktor
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Guru Besar
                </RadioItem>
                <RadioItem onChange={handleChange} name="jabatan_akademis">
                  Yang Lain
                </RadioItem>
              </RadioForm>
              <RadioForm label="Keperluan Jurnal">
                <RadioItem onChange={handleChange} name="keperluan_jurnal">
                  Untuk Syarat Kelulusan
                </RadioItem>
                <RadioItem onChange={handleChange} name="keperluan_jurnal">
                  Syarat Sidang Yudisium
                </RadioItem>
                <RadioItem onChange={handleChange} name="keperluan_jurnal">
                  BKD (Dosen)
                </RadioItem>
                <RadioItem onChange={handleChange} name="keperluan_jurnal">
                  Tugas Kuliah
                </RadioItem>
              </RadioForm>
              <div
                class="flex items-center p-4 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
                role="alert"
              >
                <svg
                  class="flex-shrink-0 inline w-4 h-4 mr-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  Diharapkan Untuk Sudah Mengisi Nomor Whatsapp Dengan Tepat
                  Yah! Dengan Segera Admin Kami Akan Menghubungi Anda
                </div>
              </div>
              <button
                disabled={loading}
                onClick={(e) => onSubmit(e)}
                type="submit"
                class="sub-inline-flex justify-center items-center py-2.5 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
              >
                {loading ? "Sedang mengirim..." : "Kirim"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
