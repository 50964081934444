import React from "react";

function PricingTitle({ children }) {
  return <h3 class="mb-4 text-2xl font-semibold">{children}</h3>;
}

function PricingSubtitle({children}){
    return <p class="mb-4 font-light text-gray-500 sm:text-lg dark:text-gray-400">
    {children}
  </p>
}


export {PricingSubtitle, PricingTitle}