import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { Helmet } from "react-helmet";

function JurnalKami() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "CollectionPage",
              "name": "Transpublika Journal",
              "url": "https://transpublika.com/journal",
              "description": "Read the latest journals on various topics at Transpublika."
            }
          `}
        </script>
        <title>Transpublika - Journal</title>
        <meta
          name="description"
          content="Read the latest journals on various topics at Transpublika."
        />
      </Helmet>
      <Header />
      <section class="bg-white dark:bg-gray-900 mt-28">
        <div class="py-8 px-4 mx-auto max-w-screen-lg lg:py-16 lg:px-6">
          <div class="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div class="flex flex-col mx-auto max-w-md text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
              <a target="_blank" href="https://transpublika.co.id/ojs/">
                <img
                  src="/assets/img/callforpaper/ojs.transpublika.co.id.png"
                  alt=""
                />
              </a>
            </div>
            <div class="flex flex-col mx-auto max-w-md text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
              <a target="_blank" href="https://ojs.transpublika.com">
                <img
                  src="/assets/img/callforpaper/ojs.transpublika.com.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppWidget
        phoneNumber={localStorage.getItem("whatsapp")}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </>
  );
}

export default JurnalKami;
