import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import {
  BlogSection,
  FaqSection,
  FeaturesSection,
  SliderIndex,
  WhatHelp,
  WhyUs,
} from "../components/home/section";
import Contact from "../components/home/section/contact";
import { Jumbotron } from "../components/lil/jumbotron";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Transpublika",
              "url": "https://transpublika.com",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://transpublika.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
        <title>Transpublika - Home</title>
        <meta name="description" content="Welcome to Transpublika, your source for the latest journals and blogs." />
      </Helmet>
      <Header />
      <Jumbotron />
      <WhyUs />
      <WhatHelp />
      <SliderIndex />
      <FeaturesSection />
      <BlogSection />
      <FaqSection />
      <Contact />
      <Footer />
      <WhatsAppWidget
        phoneNumber="6281234566573"
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </>
  );
}

export default Home;
