import React, { useContext } from "react";
import { FaEnvelope, FaPhone, FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { asset_path, backendUrl } from "../../api/const";
import { ApiContext } from "../../context/ApiProvider";

function Default() {
  const { data } = useContext(ApiContext);
  return (
    <>
      <nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="/" class="flex items-center">
            <img
              src="/assets/img/logo/logo_panjang.png"
              class="h-10 mr-3"
              alt="Transpublika Logo"
            />
          </a>
          <div class="flex md:order-2">
            <a
              href={localStorage.getItem("HashLink-form")}
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  md:block hidden"
            >
              Hubungi Kami
            </a>
            <button
              onClick={() =>
                document
                  .getElementById("navbar-sticky")
                  .classList.toggle("hidden")
              }
              data-collapse-toggle="navbar-sticky"
              type="button"
              class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <HashLink
                  to="/"
                  className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
                  aria-current="page"
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/home#whymesection"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  About
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/blog"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Blog
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/jurnal_kami"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Jurnal Kami
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/home#features"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Layanan Kami
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/home#contact"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Kontak
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

function Bussiness({ onTop }) {
  const { data } = useContext(ApiContext);
  return (
    <>
      <nav
        class={`bg-white border-gray-200 dark:bg-gray-900 fixed ${
          onTop && "w-full"
        } z-20 top-0 left-0 transition-all ease-in-out delay-150 duration-300`}
      >
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4 ">
          <HashLink to="/" class="flex items-center">
            <img
              src="/assets/img/logo/logo_panjang.png"
              class="h-10 mr-3"
              alt="Transpublika Logo"
            />
          </HashLink>
          <div class="flex items-center flex-wrap gap-4 md:mt-0 mt-4">
            <a
              href="tel:5541251234"
              class="mr-6 text-sm  text-gray-500 dark:text-white hover:underline font-bold items-center justify-center flex"
            >
              <FaEnvelope className="mr-2" /> {data.context.email}
            </a>
            <a
              href="tel:5541251234"
              class="mr-6 text-sm  text-gray-500 dark:text-white hover:underline font-bold flex items-center justify-center"
            >
              <FaPhoneAlt className="mr-2" /> {data.context.whatsapp}
            </a>
          </div>
        </div>
      </nav>
      <nav class="bg-white dark:bg-gray-700 fixed w-full lg:top-16 top-0 z-10 shadow">
        <div class="max-w-screen-xl px-4 py-3 mx-auto">
          <div class="flex items-center">
            <ul class="flex flex-row font-medium mt-0 mr-6 space-x-8 text-sm">
              <HashLink
                className="text-gray-900 dark:text-white hover:underline"
                to="/"
              >
                Home
              </HashLink>
              <li>
                <HashLink
                  to="/home#whymesection"
                  class="text-gray-900 dark:text-white hover:underline"
                >
                  About
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/home#features"
                  class="text-gray-900 dark:text-white hover:underline"
                >
                  Layanan Kami
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="text-gray-900 dark:text-white hover:underline"
                  to="/blog"
                >
                  Blog
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/jurnal_kami"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Jurnal Kami
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/home#contact"
                  class="text-gray-900 dark:text-white hover:underline"
                >
                  Kontak
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export { Default, Bussiness };
