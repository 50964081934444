import React from "react";
import { BiPackage, BiSolidBookmarkStar } from "react-icons/bi";

function FeaturesBody({ children, icon = null }) {
  return (
    <div>
      <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
        {icon ? icon : <BiSolidBookmarkStar size={40} />}
      </div>
      {children}
    </div>
  );
}

function FeaturesTitle({ children }) {
  return <h3 class="mb-2 text-xl font-bold dark:text-white">{children}</h3>;
}

function FeaturesDesc({ children }) {
  return <p class="text-gray-500 dark:text-gray-400">{children}</p>;
}

export default FeaturesBody;
export { FeaturesTitle, FeaturesDesc };
