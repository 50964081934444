import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import Swal from "sweetalert2";
import ArticleSummary from "../../lil/article";

function Blog() {
  const [article, setArticle] = useState([]);

  useEffect(() => {
    api
      .getData("/web-content/article/3")
      .then((res) => {
        setArticle(res.data.data.article);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Terjadi kesalahan!",
          "Silahkan refresh halaman ini!",
          "error"
        );
      });
  }, []);

  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Blog
          </h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Berita dan kabar terbaru
          </p>
        </div>
        <div class="grid gap-8 lg:grid-cols-2">
          {Object.keys(article).map((el) => {
            return <ArticleSummary key={article[el].id} data={article[el]} />;
          })}
        </div>
      </div>
    </section>
  );
}

export default Blog;
